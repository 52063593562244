












































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import debounce from 'lodash/debounce';
import parseJSON from 'date-fns/parseJSON';
import camelcaseKeys from 'camelcase-keys';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import formatISO from 'date-fns/formatISO';

import i18n from '@/i18n';
import {
  getInventoryAssetRequisitionNotesList,
  getProduct,
  getEmployee,
  lookupProduct,
  lookupEmployee,
} from '../api/inventory-asset-transfer-notes-api';

import { formatNumber as fn } from '../../../utils/number-formatting';

@Component
export default class extends Vue {
  inventoryOptions: Array<any> = [];
  isInventoryOptionsLoading = false;

  formatNumber(input, dp) {
    return fn(input, dp);
  }

  sessionStorage = window.sessionStorage;
  localStorage = window.localStorage;

  dateInterval = [startOfMonth(new Date()), endOfMonth(new Date())];
  datepickerShortcuts = [{
    text: 'Astăzi',
    onClick: () => [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    text: 'Ieri',
    onClick: () => [addDays(startOfDay(new Date()), -1), addDays(endOfDay(new Date()), -1)],
  },
  {
    text: 'Luna curentă',
    onClick: () => [startOfMonth(new Date()), endOfMonth(new Date())],
  }, {
    text: 'Luna trecută',
    onClick: () => [addMonths(startOfMonth(new Date()), -1), endOfMonth(addMonths(new Date(), -1))],
  }, {
    text: 'Ultimele 3 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -2), endOfMonth(new Date())],
  }, {
    text: 'Ultimele 6 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -5), endOfMonth(new Date())],
  }, {
    text: 'Anul curent',
    onClick: () => [startOfYear(new Date()), endOfYear(new Date())],
  }, {
    text: 'Anul trecut',
    onClick: () => [addYears(startOfYear(new Date()), -1), addYears(endOfYear(new Date()), -1)],
  }];

  statusOptions = [
    { status: 1, label: 'Procesat' },
    { status: 0, label: 'Ciornă' },
    { status: 2, label: 'Anulat' },
  ];

  startDate = startOfMonth(new Date());
  endDate = endOfMonth(new Date());
  inventory: any|null = null;
  status: any|null = null;

  selectedProduct: any = null;
  productsOptions: Array<any> = [];
  isProductsOptionsLoadiung = false;

  selectedEmployee: any|null = null;
  multiselectEmployeeList: Array<any> = [];
  multiselectEmployeeLoading = false;

  async asyncSearchInventory(query) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.isInventoryOptionsLoading = true;

    this.inventoryOptions = camelcaseKeys(await lookupEmployee(query));

    this.isInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  async asyncSearchEmployees(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.multiselectEmployeeLoading = true;

    this.multiselectEmployeeList = camelcaseKeys(await lookupEmployee(query));

    this.multiselectEmployeeLoading = false;
    return Promise.resolve();
  }

  debouncedSearchEmployees = debounce(this.asyncSearchEmployees, 75, { maxWait: 150 });

  async asyncSearchProducts(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }
    this.isProductsOptionsLoadiung = true;
    this.productsOptions = camelcaseKeys(await lookupProduct(query));
    this.isProductsOptionsLoadiung = false;
    return Promise.resolve();
  }

  debouncedSearchProducts = debounce(this.asyncSearchProducts, 75, { maxWait: 150 });
  debouncedSearchInventory = debounce(this.asyncSearchInventory, 75, { maxWait: 150 });

  addToIATNSessionStorage(key, value) {
    sessionStorage.setItem('iatn_grid_session_'.concat(key), value);
  }
  getFromIATNSessionStorage(key) {
    return sessionStorage.getItem('iatn_grid_session_'.concat(key)) || '';
  }
  addToIATNLocalStorage(key, value) {
    sessionStorage.setItem('iatn_grid_local_'.concat(key), value);
  }
  getFromIATNLocalStorage(key) {
    return sessionStorage.getItem('iatn_grid_local_'.concat(key)) || '';
  }

  onReportIntervalChange(val) {
    [this.startDate, this.endDate] = val;
    this.dateInterval = val;

    this.onApplyFilters();
  }

  hoveredRowIndex = -1;

  items: Array<any> = [];

  tableLoaded = false;
  tableBusy = false;

  itemsPerPage = 25;
  currentGridPage = 1;
  allItemsCount = 0;

  editItemsPerPage = this.itemsPerPage;

  badgeVariant(statusCode) {
    switch (statusCode) {
      case 0:
        return 'secondary';
      case 1:
        return 'success';
      case 2:
        return 'warning';
      case 3:
        return 'primary';
      default:
        return 'secondary';
    }
  }

  async loadGridPage(page, perPage) {
    try {
      this.tableBusy = true;

      const listResult = camelcaseKeys(await getInventoryAssetRequisitionNotesList(page, perPage, {
        startDate: formatISO(this.dateInterval[0]),
        endDate: formatISO(this.dateInterval[1]),
        inventoryId: this.inventory?.employeeId,
        statusCode: this?.status?.status,
        productId: this?.selectedProduct?.productId,
        employeeId: this?.selectedEmployee?.employeeId,
      }));

      this.allItemsCount = listResult.count;
      this.items = listResult.items.map((x) => ({
        ...x,
        gestiune: '',
      }));
    } finally {
      this.tableLoaded = true;
      this.tableBusy = false;
    }
  }

  async initialize() {
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  async created() {
    this.inventory = await getEmployee(this.getFromIATNSessionStorage('inventoryId'));
    this.selectedProduct = await getProduct(this.getFromIATNSessionStorage('productId'));
    this.selectedEmployee = await getEmployee(this.getFromIATNSessionStorage('employeeId'));
    if (this.getFromIATNSessionStorage('startDate') && this.getFromIATNSessionStorage('endDate')) {
      this.dateInterval = [new Date(Date.parse(this.getFromIATNSessionStorage('startDate'))), new Date(Date.parse(this.getFromIATNSessionStorage('endDate')))];
    }
    this.status = this.getStatusOptionObject(this.getFromIATNSessionStorage('status'));
    if (this.getFromIATNLocalStorage('itemsPerPage')) {
      this.itemsPerPage = parseInt(this.getFromIATNLocalStorage('itemsPerPage'), 10);
      this.editItemsPerPage = this.itemsPerPage;
    }
    this.initialize();
  }

  async onApplyFilters() {
    if (!this.editItemsPerPage) {
      this.editItemsPerPage = 25;
    }
    this.itemsPerPage = this.editItemsPerPage;
    this.storeFilters();
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  async onApplyItemsPerPage() {
    if (!this.editItemsPerPage) {
      this.editItemsPerPage = 25;
    }
    this.itemsPerPage = this.editItemsPerPage;
    this.storeFilters();
    await this.loadGridPage(1, this.itemsPerPage);
  }

  async onCurrentGridPageChanged(page) {
    this.currentGridPage = page;
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  storeFilters() {
    this.addToIATNSessionStorage('inventoryId', this.inventory?.employeeId);
    this.addToIATNSessionStorage('startDate', formatISO(this.dateInterval[0]));
    this.addToIATNSessionStorage('endDate', formatISO(this.dateInterval[1]));
    this.addToIATNSessionStorage('status', this.status?.status);
    this.addToIATNSessionStorage('productId', this.selectedProduct?.productId);
    this.addToIATNSessionStorage('employeeId', this.selectedEmployee?.employeeId);
    this.addToIATNLocalStorage('itemsPerPage', this.itemsPerPage);
  }

  getStatusOptionObject(status) {
    return this.statusOptions.filter((option) => option.status === parseInt(status, 10))[0];
  }

  rowHovered(_: unknown, index: number) {
    this.hoveredRowIndex = index;
  }

  rowUnhovered() {
    this.hoveredRowIndex = -1;
  }

  get parseJSON() { return parseJSON; }

  get fields() {
    return [
      {
        key: 'numar',
        label: i18n.t('iatn.grid.numar'),
      },
      {
        key: 'data',
        label: i18n.t('iatn.grid.data'),
      },
      {
        key: 'gestiune',
        label: i18n.t('iatn.grid.inventories'),
      },
      {
        key: 'requisitionedBy',
        label: i18n.t('iatn.grid.releasedBy'),
      },
      {
        key: 'stare',
        label: i18n.t('iatn.grid.stare'),
      },
      {
        key: 'actiuni',
        label: i18n.t('iatn.grid.actiuni'),
        thClass: 'col-action',
        tdClass: 'col-action-body',
      },
    ];
  }
}
